<template>
  <VaLayout v-if="breakpoint.lgUp" class="h-screen bg-[var(--va-background-secondary)] seda-auth-wrapper">
    <template #left>
      <RouterLink
        class="h-full flex items-center justify-center text-white"
        style="
          width: 35vw;
          background-image: url('/login-img.png');
          background-size: cover;
          background-position: center;
        "
        to="/"
        aria-label="Visit homepage"
      >
      </RouterLink>
    </template>
    <template #content>
      <main class="h-full flex items-center justify-center mx-auto max-w-[500px]">
        <RouterView />
      </main>
    </template>
  </VaLayout>

  <VaLayout v-else class="h-screen bg-[var(--va-background-secondary)]">
    <template #content>
      <div class="p-4">
        <main class="h-full flex flex-row items-center justify-start mx-auto max-w-[420px]">
          <div class="flex flex-col items-start">
            <RouterLink class="py-4" to="/" aria-label="Visit homepage">
              <VuesticLogo class="mb-2" start="#0E41C9" />
            </RouterLink>
            <RouterView />
          </div>
        </main>
      </div>
    </template>
  </VaLayout>
</template>

<script lang="ts" setup>
import { useBreakpoint } from 'vuestic-ui'
import VuesticLogo from '../components/VuesticLogo.vue'

const breakpoint = useBreakpoint()
</script>

<style setup>
.seda-form {
  box-shadow:
    -5px 6px 20px -5px rgb(0 0 0 / 20%),
    5px -5px 5px -10px rgb(0 0 0 / 30%);
  text-align: center;
  padding: 40px;
  border-radius: 50px;
}

.seda-form .va-input-wrapper__container {
  width: 100%;
  margin-bottom: 15px;
}

.seda-form .va-input-wrapper__text {
  height: 30px;
}

.seda-form .va-input-wrapper__field {
  border-radius: 10px;
}

.seda-form label {
  font-size: 14px;
}

.seda-form h1 {
  margin-bottom: 35px;
}

.seda-form {
  text-align: center;
  padding: 40px;
  border-radius: 50px;
  color: #154ec1;
  box-sizing: border-box;
  background: #ecf0f3;
  box-shadow:
    14px 14px 20px #cbced1,
    -14px -14px 20px white;
}
/* .seda-auth-wrapper .va-layout__area--content {
  background: #ecf0f3;
} */

.seda-form .va-button {
  border-radius: 15px;
  padding: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.seda-auth-wrapper .va-layout__area--left > a {
  position: relative;
  font-size: 30px;
}

.seda-auth-wrapper .va-layout__area--left > a:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.6;
  z-index: -1;
}

.seda-auth-wrapper .va-layout__area--left > a .text-xl {
  font-size: 32px;
  margin: 20px 0 10px;
}

.seda-auth-wrapper .va-layout__area--left > a .text-lg {
  font-size: 20px;
}

/* .seda-text[data-v-54ace9c6] {
    color: #0055ff;
    font-size: 1.5em;
} */

.experts-text[data-v-54ace9c6] {
  color: rgb(247 247 247);
}

.va-input-wrapper__field {
  border-color: #517dcf;
}

.max-w-\[420px\] a.py-4 {
  margin: auto;
}
</style>
