<template>
  <div class="logo-container">
    <VaImage src="/SEDA-Logo-org.png" alt="Seda Logo" class="logo-image" />
  </div>
</template>

<style scoped>
.logo-container {
  display: flex;
}

.logo-image {
  width: 40px;
  margin-right: 5px;
  min-width: 150px;
  height: 50px;
}
</style>
