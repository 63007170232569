import { Job, SyncProgress } from '../components/navbar/type'
import { httpClient } from '../helpers/axios-wrapper'
import { DashboardMetric } from '../pages/admin/dashboard/types'

export const dashboardService = {
  getMetrics,
  getBillMetrics,
  getMasterSyncProgress,
  startMasterSync,
}

async function getMetrics(): Promise<DashboardMetric[]> {
  return await httpClient.get<DashboardMetric[]>(`/metrics`)
}

async function getBillMetrics(): Promise<DashboardMetric[]> {
  return await httpClient.get<DashboardMetric[]>(`/billmetrics`)
}

async function getMasterSyncProgress(): Promise<SyncProgress> {
  return await httpClient.get<SyncProgress>(`/master-sync-progress`)
}

async function startMasterSync(): Promise<Job> {
  return await httpClient.post<Job>(`/start-master-sync`, {})
}
