import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'
import { useAuthStore } from '../stores/auth-store'
import RouteViewComponent from '../layouts/RouterBypass.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'dashboard' },
  },
  {
    name: 'admin',
    path: '/',
    component: AppLayout,
    redirect: { name: 'dashboard' },
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('../pages/admin/dashboard/Dashboard.vue'),
      },
      {
        name: 'settings',
        path: 'settings',
        component: () => import('../pages/settings/Settings.vue'),
      },
      {
        name: 'preferences',
        path: 'preferences',
        component: () => import('../pages/preferences/Preferences.vue'),
      },
      {
        name: 'billing',
        path: '/billing',
        component: () => import('../pages/404.vue'),
      },
      {
        name: 'faq',
        path: '/faq',
        component: () => import('../pages/faq/FaqPage.vue'),
      },

      {
        name: 'bills',
        path: '/bills',
        component: () => import('../pages/bills/BillsPage.vue'),
      },
      {
        name: 'add-cogs',
        path: '/add-cogs/:id?',
        component: () => import('../pages/cogs/AddCogsPage.vue'),
        props: true,
      },
      {
        name: 'payments',
        path: '/payments',
        component: () => import('../pages/payments/ApprovedBillsPage.vue'),
      },
      {
        name: 'timeline',
        path: '/timeline',
        component: () => import('../pages/timelines/TimeLinesPage.vue'),
      },
      {
        name: 'revised',
        path: '/revised',
        component: () => import('../pages/revised-bills/VoidBillsPage.vue'),
      },
      {
        name: 'data',
        path: '/data',
        component: RouteViewComponent,
        children: [
          {
            name: 'customers',
            path: '/customers',
            component: () => import('../pages/customers/CustomersPage.vue'),
          },
          {
            name: 'users',
            path: '/users',
            component: () => import('../pages/users/UsersPage.vue'),
          },
          {
            name: 'projects',
            path: '/projects',
            component: () => import('../pages/projects/ProjectsPage.vue'),
          },
          {
            name: 'vendors',
            path: '/vendors',
            component: () => import('../pages/vendors/VendorsPage.vue'),
          },
          {
            name: 'invoices',
            path: '/invoices',
            component: () => import('../pages/invoices/InvoicesPage.vue'),
          },
          {
            name: 'cogs',
            path: '/cogs',
            component: () => import('../pages/cogs/CogsPage.vue'),
          },
        ],
      },
      // {
      //   name: 'access-management',
      //   path: '/access-management',
      //   component: RouteViewComponent,
      //   children: [
      //     {
      //       name: 'app-members',
      //       path: '/app-members',
      //       component: () => import('../pages/app-users/AppUsersPage.vue'),
      //     }
      //   ],
      // },
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
      },
      {
        name: 'signup',
        path: 'signup',
        component: () => import('../pages/auth/Signup.vue'),
      },
      {
        name: 'recover-password',
        path: 'recover-password',
        component: () => import('../pages/auth/RecoverPassword.vue'),
      },
      {
        name: 'recover-password-email',
        path: 'recover-password-email',
        component: () => import('../pages/auth/CheckTheEmail.vue'),
      },
      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },
]

export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

router.beforeEach(async (to) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/auth/login', '/auth/signup']
  const authRequired = !publicPages.includes(to.path)
  const auth = useAuthStore()

  if (authRequired && (!auth || !auth.user)) {
    return '/auth/login'
  }
})

// export default router
