export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'menu.dashboard',
      meta: {
        icon: 'vuestic-iconset-dashboard',
      },
    },
    {
      name: 'bills',
      displayName: 'menu.bills',
      meta: {
        icon: 'receipt_long',
      },
    },
    {
      name: 'payments',
      displayName: 'menu.payments',
      meta: {
        icon: 'payments',
      },
    },
    {
      name: 'revised',
      displayName: 'menu.revisedbills',
      meta: {
        icon: 'credit_score',
      },
    },
    {
      name: 'data',
      displayName: 'menu.data',
      meta: {
        icon: 'folder_copy',
      },
      children: [
        {
          name: 'customers',
          displayName: 'menu.customers',
          meta: {
            icon: 'supervisor_account',
          },
        },
        {
          name: 'users',
          displayName: 'menu.users',
          meta: {
            icon: 'diversity_3',
          },
        },
        {
          name: 'vendors',
          displayName: 'menu.vendors',
          meta: {
            icon: 'account_box',
          },
        },
        {
          name: 'projects',
          displayName: 'menu.projects',
          meta: {
            icon: 'credit_card',
          },
        },
        {
          name: 'invoices',
          displayName: 'menu.invoices',
          meta: {
            icon: 'inventory',
          },
        },
        {
          name: 'cogs',
          displayName: 'menu.cogs',
          meta: {
            icon: 'computer',
          },
        },
      ],
    },
    {
      name: 'timeline',
      displayName: 'menu.timeline',
      meta: {
        icon: 'next_plan',
      },
    },
    {
      name: 'preferences',
      displayName: 'menu.preferences',
      meta: {
        icon: 'manage_accounts',
      },
    },
    // {
    //   name: 'access-management',
    //   displayName: 'menu.accessmanagement',
    //   meta: {
    //     icon: 'widgets',
    //   },
    //   children: [
    //     {
    //       name: 'app-members',
    //       displayName: 'menu.appusers',
    //       meta: {
    //         icon: 'manage_accounts',
    //       },
    //     }
    //   ],
    // },
  ] as INavigationRoute[],
}
