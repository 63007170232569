import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { useAuthStore } from '../stores/auth-store'
import { useToast } from 'vuestic-ui'

const baseUrl = `${import.meta.env.VITE_API_URL}`

interface RequestOptions extends Omit<AxiosRequestConfig, 'headers'> {
  headers?: Record<string, string>
  data?: any
}

export interface ErrorResponse {
  message: string
  success: boolean
}

export interface PaginationParams {
  page: number
  per_page: number
  sort_by: string
  sort_order: string
  search_fields: string[]
  search_value: string
  search_values: string[]
  export?: boolean
  format?: string
}

export type PaginationResponse<T> = {
  current_page: number
  data: T[]
  first_page_url: string
  from: number
  last_page: number
  last_page_url: string
  links: { url: string | null; label: string; active: boolean }[]
  next_page_url: string | null
  path: string
  per_page: number
  prev_page_url: string | null
  to: number
  total: number
  fileUrl?: string
}

export const httpClient = {
  get,
  post,
  put,
  delete: del,
}

// Generic GET request function
async function get<T>(url: string): Promise<T> {
  return await request<T>('GET', url)
}

// Generic POST request function
async function post<T>(url: string, body: any): Promise<T> {
  return await request<T>('POST', url, body)
}

// Generic PUT request function
async function put<T>(url: string, body: any): Promise<T> {
  return await request<T>('PUT', url, body)
}

// Generic DELETE request function
async function del<T>(url: string): Promise<T> {
  return await request<T>('DELETE', url)
}

// Generic request function
async function request<T>(method: string, url: string, data?: any): Promise<T> {
  const requestOptions: RequestOptions = {
    method,
    url: `${baseUrl}${url}`,
    headers: {
      ...authHeader(), // Existing headers from authHeader function
      Accept: 'application/json', // Adding Accept header
    },
    data, // Axios uses 'data' instead of 'body'
  }

  try {
    const response = await axios(requestOptions)
    return handleResponse<T>(response)
  } catch (error: any) {
    const { logout } = useAuthStore()
    if ([401, 403].includes(error.status)) {
      logout()
    }
    const { init } = useToast()
    init({ message: error.response?.data?.message, color: 'danger' })
    // Extract the error message or fallback to a default message
    const errorMessage = error.response?.data?.message || error.message || 'An unknown error occurred'
    return Promise.reject(new Error(errorMessage))
  }
}

// Helper function for authorization headers
function authHeader(): Record<string, string> {
  const authStore = useAuthStore()
  const access_token = authStore.GetAuthState().access_token
  const isLoggedIn = !!access_token
  // console.log(access_token);
  if (isLoggedIn) {
    return { Authorization: `Bearer ${access_token}` }
  } else {
    return {}
  }
}

// Helper function for handling response
function handleResponse<T>(response: AxiosResponse<T>): T {
  if (response.status >= 200 && response.status < 300) {
    return response.data
  } else {
    const { logout } = useAuthStore()
    if ([401, 403].includes(response.status)) {
      logout()
    }
    // Cast response.data to ErrorResponse to access 'message'
    const errorResponse = response.data as ErrorResponse
    const error = errorResponse.message || response.statusText
    throw new Error(error) // Throwing an error instead of returning a rejected promise
  }
}
